import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ClientModal } from "./ClientModal";
import axios from "axios";
import config from "../../config.json";
import { Loading } from "../Loading";

export const Clients = () => {
  const [showModal, setShowModal] = useState(false);
  const [clients, setClients] = useState(null);
  const [activeClientData, setActiveClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const addClient = () => {
    setActiveClientData({});
    setShowModal(true);
  };

  const editClient = (data) => {
    setActiveClientData({
      ...data,
      meta: !!data?.meta ? JSON.parse(data?.meta) : {},
    });
    setShowModal(true);
  };

  const metaValue = (data, value) => JSON.parse(data)?.[value];

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${config.api[window.location.hostname]}/clientList.php`)
      .then((response) => {
        setClients(response.data.clients);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loading />;
  if (!clients) return null;

  return (
    <>
      <ClientModal
        data={activeClientData}
        setClients={setClients}
        setData={setActiveClientData}
        setIsLoading={setIsLoading}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <div className="mb-3 text-end">
        <Button onClick={addClient}>
          <FontAwesomeIcon icon={faPlus} inverse /> Add New Client
        </Button>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(clients ?? []).map((client) => (
            <tr key={client.id}>
              <td>{client.name} </td>
              <td className="text-end">
                {!metaValue(client?.meta, "isAdmin") && (
                  <Button variant="dark" onClick={() => editClient(client)}>
                    <FontAwesomeIcon icon={faPencil} /> {}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
