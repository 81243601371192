import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import { FilterBar } from "../Global/FilterBar";
import { useCookies } from "react-cookie";
import { Loading } from "../Loading";

export const ITVReport = () => {
  const [cookies] = useCookies(["user"]);
  const [itvs, setITVs] = useState(null);
  const [query, setQuery] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let totalRecords = 0,
    totalInit = 0,
    totalAdd = 0,
    totalNumDonations = 0,
    totalUniqueDonors = 0;

  const additionalRow = (isDonor, record, days) => {
    const value =
      (parseFloat(record[`amount${days}`] ?? "0") +
        parseFloat(itvs[isDonor ? 1 : 0]["amount0"] ?? "0")) /
      parseFloat(record[`users`] ?? "0");

    return (
      <tr key={days} id={days}>
        <td>{days}-Day Dnr</td>
        <td>
          ${parseFloat(record[`amount${days}`] ?? "0").toLocaleString("en-US")}
        </td>
        <td>
          {parseFloat(record[`tally${days}`] ?? "0").toLocaleString("en-US")}
        </td>
        <td>
          {parseFloat(record[`tallyUnique${days}`] ?? "0").toLocaleString(
            "en-US"
          )}
        </td>
        <td>${(isNaN(value) ? 0 : value).toLocaleString("en-US")}</td>
      </tr>
    );
  };

  const updateQuery = (newQuery, newDateFrom, newDateTo) => {
    setQuery(newQuery);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `${
          config.api[window.location.hostname]
        }/itvReport.php?cache=${new Date().getTime()}&source=${
          cookies.user.clientMeta.procedureITV
        }&query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}`
      )
      .then((response) => {
        setITVs(response.data.itvs);
        setIsLoading(false);
      });
  }, [cookies, dateFrom, dateTo, query]);

  if (isLoading) return <Loading />;
  if (!itvs) return null;

  return (
    <>
      <div>
        <Row className="alignItemsBaseline">
          <Col md={9} className="mb-3">
            <FilterBar
              dateFrom={dateFrom}
              dateTo={dateTo}
              query={query}
              onUpdateQuery={updateQuery}
            />
          </Col>
          <Col md={3} className="mb-3 text-end"></Col>
        </Row>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Category</th>
            <th>Records</th>
            <th>Init. $$</th>
            <th>Add. $$</th>
            <th># Donations</th>
            <th>Uniq. Donors</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {(itvs ?? []).map((record) => {
            totalRecords += parseInt(record.users ?? "0");
            totalInit += parseFloat(record.amount0 ?? "0");
            totalAdd += parseFloat(record.amountInfinity ?? "0");
            totalNumDonations += parseInt(record.tally ?? "0");
            totalUniqueDonors += parseInt(record.usersUnique ?? "0");

            return (
              <tr key={record.initial_donor ? "Donors" : "Non-Donors"}>
                <td>{record.initial_donor ? "Donors" : "Non-Donors"}</td>
                <td>{parseInt(record.users ?? "0").toLocaleString("en-US")}</td>
                <td>
                  ${parseFloat(record.amount0 ?? "0").toLocaleString("en-US")}
                </td>
                <td>
                  $
                  {parseFloat(record.amountInfinity ?? "0").toLocaleString(
                    "en-US"
                  )}
                </td>
                <td>
                  {parseFloat(record.tally ?? "0").toLocaleString("en-US")}
                </td>
                <td>
                  {parseFloat(record.usersUnique ?? "0").toLocaleString(
                    "en-US"
                  )}
                </td>
                <td>
                  $
                  {(
                    (parseFloat(record.amount0 ?? "0") +
                      parseFloat(record.amountInfinity ?? "0")) /
                    parseFloat(record.users ?? "0")
                  ).toLocaleString("en-US")}
                </td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{totalRecords.toLocaleString("en-US")}</td>
            <td>${totalInit.toLocaleString("en-US")}</td>
            <td>${totalAdd.toLocaleString("en-US")}</td>
            <td>{totalNumDonations.toLocaleString("en-US")}</td>
            <td>{totalUniqueDonors.toLocaleString("en-US")}</td>
            <td>
              ${((totalInit + totalAdd) / totalRecords).toLocaleString("en-US")}
            </td>
          </tr>
        </tbody>
      </Table>

      <Card bg="dark" text="light" className="mt-5 mb-3">
        <Card.Body>Donor Additional Donations</Card.Body>
      </Card>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Time Span</th>
            <th>Total Donations</th>
            <th># Donations</th>
            <th>Uniq. Donors</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {(itvs ?? []).map((record) => [
            record.initial_donor && additionalRow(true, record, 30),
            record.initial_donor && additionalRow(true, record, 60),
            record.initial_donor && additionalRow(true, record, 90),
            record.initial_donor && additionalRow(true, record, 180),
            record.initial_donor && additionalRow(true, record, 270),
            record.initial_donor && additionalRow(true, record, 360),
            record.initial_donor && additionalRow(true, record, 450),
            record.initial_donor && additionalRow(true, record, 540),
            record.initial_donor && additionalRow(true, record, 630),
            record.initial_donor && additionalRow(true, record, 720),
          ])}
        </tbody>
      </Table>

      <Card bg="dark" text="light" className="mt-5 mb-3">
        <Card.Body>Non-Donor Additional Donations</Card.Body>
      </Card>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Time Span</th>
            <th>Total Donations</th>
            <th># Donations</th>
            <th>Uniq. Donors</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {(itvs ?? []).map((record) => [
            !record.initial_donor && additionalRow(false, record, 30),
            !record.initial_donor && additionalRow(false, record, 60),
            !record.initial_donor && additionalRow(false, record, 90),
            !record.initial_donor && additionalRow(false, record, 180),
            !record.initial_donor && additionalRow(false, record, 270),
            !record.initial_donor && additionalRow(false, record, 360),
            !record.initial_donor && additionalRow(false, record, 450),
            !record.initial_donor && additionalRow(false, record, 540),
            !record.initial_donor && additionalRow(false, record, 630),
            !record.initial_donor && additionalRow(false, record, 720),
          ])}
        </tbody>
      </Table>
    </>
  );
};
