import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowUp,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { ProspectModal } from "./ProspectModal";
import axios from "axios";
import config from "../../config.json";
import { SortableTH } from "../Global/SortableTH";
import { FilterBar } from "../Global/FilterBar";
import { PaginationBar } from "../Global/PaginationBar";
import { useCookies } from "react-cookie";
import { ProspectUploadModal } from "./ProspectUploadModal";
import { Loading } from "../Loading";

export const Prospects = () => {
  const [cookies] = useCookies(["user"]);
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [tally, setTally] = useState(null);
  const [prospects, setProspects] = useState(null);
  const [activeProspectData, setActiveProspectData] = useState({});
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [query, setQuery] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const addProspect = () => {
    setActiveProspectData({});
    setShowModal(true);
  };

  const editProspect = (data) => {
    setActiveProspectData({
      ...data,
    });
    setShowModal(true);
  };

  const updateQuery = (newQuery, newDateFrom, newDateTo) => {
    setQuery(newQuery);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  const updatePagination = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const updateSortField = (field) => {
    if (field === sortField) {
      if (sortOrder === "ASC") setSortOrder("DESC");
      else setSortOrder("ASC");
    }

    setSortField(field);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `${
          config.api[window.location.hostname]
        }/prospectList.php?cache=${new Date().getTime()}&source=${
          cookies.user.clientMeta.tableProspects
        }&query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortField=${sortField}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .then((response) => {
        setTally(response.data.tally);
        setProspects(response.data.prospects);
        setIsLoading(false);
      });
  }, [
    cookies,
    dateFrom,
    dateTo,
    pageIndex,
    pageSize,
    query,
    sortField,
    sortOrder,
  ]);

  if (isLoading) return <Loading />;

  if (!prospects) return null;

  return (
    <>
      {!!cookies?.user?.meta?.prospectsCanCreate && (
        <ProspectModal
          data={activeProspectData}
          setProspects={setProspects}
          setTally={setTally}
          setData={setActiveProspectData}
          setIsLoading={setIsLoading}
          show={showModal}
          onHide={() => setShowModal(false)}
          /*--- Filters ---*/
          dateFrom={dateFrom}
          dateTo={dateTo}
          pageIndex={pageIndex}
          pageSize={pageSize}
          query={query}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}

      {!!cookies?.user?.meta?.prospectsCanImport && (
        <ProspectUploadModal
          setIsLoading={setIsLoading}
          setProspects={setProspects}
          setTally={setTally}
          show={showUploadModal}
          onHide={() => setShowUploadModal(false)}
          /*--- Filters ---*/
          dateFrom={dateFrom}
          dateTo={dateTo}
          pageIndex={pageIndex}
          pageSize={pageSize}
          query={query}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}

      <div className="mb-3 text-end">
        <Row className="alignItemsBaseline">
          <Col md={9}>
            <FilterBar
              dateFrom={dateFrom}
              dateTo={dateTo}
              query={query}
              onUpdateQuery={updateQuery}
            />
          </Col>
          <Col md={3}>
            <ButtonGroup>
              {!!cookies?.user?.meta?.prospectsCanImport && (
                <Button
                  variant="light"
                  onClick={() => setShowUploadModal(true)}
                >
                  <FontAwesomeIcon icon={faFileArrowUp} /> Upload
                </Button>
              )}

              {!!cookies?.user?.meta?.prospectsCanCreate && (
                <Button onClick={addProspect}>
                  <FontAwesomeIcon icon={faPlus} inverse /> Add
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <SortableTH
              field="date"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Date
            </SortableTH>
            <SortableTH
              field="vendor"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Vendor
            </SortableTH>
            <SortableTH
              field="placement"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Placement
            </SortableTH>
            <SortableTH
              field="cost"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Cost
            </SortableTH>
            <SortableTH
              field="source"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Source
            </SortableTH>
            <SortableTH
              field="message_id"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Message ID
            </SortableTH>
            <SortableTH
              field="creative"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Creative
            </SortableTH>
            <SortableTH
              field="Organization"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Organization
            </SortableTH>
            {!!cookies?.user?.meta?.prospectsCanEdit && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {(prospects ?? []).map((prospect) => (
            <tr key={prospect.id}>
              <td>{prospect.date} </td>
              <td>{prospect.vendor} </td>
              <td>{prospect.placement} </td>
              <td>${parseFloat(prospect.cost).toLocaleString("en-US")} </td>
              <td>{prospect.source} </td>
              <td>{prospect.message_id} </td>
              <td>{prospect.creative} </td>
              <td>{prospect.organization} </td>
              {!!cookies?.user?.meta?.prospectsCanEdit && (
                <td className="text-end">
                  <Button variant="dark" onClick={() => editProspect(prospect)}>
                    <FontAwesomeIcon icon={faPencil} /> {}
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <PaginationBar
        pageIndex={pageIndex}
        pageSize={pageSize}
        tally={tally}
        onUpdatePagination={updatePagination}
      />
    </>
  );
};
