import React from "react";
import { Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const FilterBar = ({
  dateFrom: iDateFrom,
  dateTo: iDateTo,
  hideQuery,
  query: iQuery,
  onUpdateQuery,
  prefix,
}) => {
  const [query, setQuery] = useState(iQuery ?? null);
  const [dateFrom, setDateFrom] = useState(iDateFrom ?? null);
  const [dateTo, setDateTo] = useState(iDateTo ?? null);

  return (
    <InputGroup>
      {prefix}
      {!hideQuery && (
        <FloatingLabel label="query">
          <Form.Control
            value={query ?? ""}
            onChange={(e) => setQuery(e.target.value)}
          />
        </FloatingLabel>
      )}
      <FloatingLabel label="from date">
        <Form.Control
          type="date"
          value={dateFrom ?? ""}
          onChange={(e) => setDateFrom(e.target.value)}
        />
      </FloatingLabel>
      <FloatingLabel label="to date">
        <Form.Control
          type="date"
          value={dateTo ?? ""}
          onChange={(e) => setDateTo(e.target.value)}
        />
      </FloatingLabel>
      <Button onClick={() => onUpdateQuery(query, dateFrom, dateTo)}>
        <FontAwesomeIcon icon={faMagnifyingGlass} /> {}
      </Button>
    </InputGroup>
  );
};
