import React from "react";
import { HomeCard } from "./HomeCard";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCreditCard,
  faDollarSign,
  faList,
  faSignature,
  faSitemap,
  faUser,
  faUsers,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

export const Home = () => {
  return (
    <Row>
      <HomeCard
        permissions={["isAdmin"]}
        url="/accounts"
        icon={<FontAwesomeIcon icon={faUser} />}
        label="Accounts"
      />

      <HomeCard
        permissions={["isAdmin"]}
        url="/clients"
        icon={<FontAwesomeIcon icon={faSitemap} />}
        label="Clients"
      />

      <HomeCard
        permissions={[
          "accountsCanCreate",
          "accountsCanEdit",
          "accountsCanView",
        ]}
        url="/clientAccounts"
        icon={<FontAwesomeIcon icon={faUser} />}
        label="Accounts"
      />

      <HomeCard
        permissions={[
          "prospectsCanCreate",
          "prospectsCanEdit",
          "prospectsCanView",
        ]}
        url="/prospects"
        icon={<FontAwesomeIcon icon={faList} />}
        label="Prospects"
      />

      <HomeCard
        permissions={["submissionsCanView"]}
        url="/submissions"
        icon={<FontAwesomeIcon icon={faSignature} />}
        label="Submissions"
      />

      <HomeCard
        permissions={[
          "transactionsCanCreate",
          "transactionsCanEdit",
          "transactionsCanView",
        ]}
        url="/transactions"
        icon={<FontAwesomeIcon icon={faCreditCard} />}
        label="Transactions"
      />

      <HomeCard
        permissions={["usersCanView"]}
        url="/users"
        icon={<FontAwesomeIcon icon={faUsers} />}
        label="Users"
      />

      <HomeCard
        permissions={["reportHousefleCanView"]}
        isReport
        url="/reportHousefile"
        icon={<FontAwesomeIcon icon={faWarehouse} />}
        label="House File Report"
      />

      <HomeCard
        permissions={["reportProspectCanView"]}
        isReport
        url="/reportProspect"
        icon={<FontAwesomeIcon icon={faList} />}
        label="Prospect Report"
      />

      <HomeCard
        permissions={["reportITVCanView"]}
        isReport
        url="/reportITV"
        icon={
          <>
            <FontAwesomeIcon icon={faDollarSign} />
            <FontAwesomeIcon icon={faClock} />
          </>
        }
        label="ITV Report"
      />
    </Row>
  );
};
