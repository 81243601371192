import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ClientAccountModal } from "./ClientAccountModal";
import axios from "axios";
import config from "../../config.json";
import { useCookies } from "react-cookie";
import { Loading } from "../Loading";

export const ClientAccounts = () => {
  const [cookies] = useCookies("user");
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [activeAccountData, setActiveAccountData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const addAccount = () => {
    setActiveAccountData({});
    setShowModal(true);
  };

  const editAccount = (data) => {
    setActiveAccountData({
      ...data,
      meta: !!data?.meta ? JSON.parse(data?.meta) : {},
    });
    setShowModal(true);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `${
          config.api[window.location.hostname]
        }/clientAccountList.php?client_id=${cookies.user.clientId}`
      )
      .then((response) => {
        setAccounts(response.data.accounts);
        setIsLoading(false);
      });
  }, [cookies]);

  if (isLoading) return <Loading />;
  if (!accounts) return null;

  return (
    <>
      <ClientAccountModal
        data={activeAccountData}
        setAccounts={setAccounts}
        setData={setActiveAccountData}
        setIsLoading={setIsLoading}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <div className="mb-3 text-end">
        <Button onClick={addAccount}>
          <FontAwesomeIcon icon={faPlus} inverse /> Add
        </Button>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(accounts ?? []).map((account) => (
            <tr key={account.id}>
              <td>{account.username} </td>
              <td className="text-end">
                <Button variant="dark" onClick={() => editAccount(account)}>
                  <FontAwesomeIcon icon={faPencil} /> {}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
