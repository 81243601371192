import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

export const HomeCard = ({ icon, isReport, label, permissions, url }) => {
  const [cookies] = useCookies(["user"]);

  if (
    permissions
      .map((permission) => (!!cookies?.user?.meta?.[permission] ? true : null))
      .filter((a) => a).length === 0
  )
    return null;

  return (
    <Col className="mb-3" xs={12} md={4} lg={3}>
      <a className="text-decoration-none" href={url}>
        <Card className={`${isReport && "text-primary"}`}>
          <Card.Body className="fs-1 text-center">
            {isReport && (
              <div className="fs-5 float-start text-primary">
                <FontAwesomeIcon icon={faChartPie} />
              </div>
            )}
            {icon}
          </Card.Body>
          <Card.Footer className="fs-2">{label}</Card.Footer>
        </Card>
      </a>
    </Col>
  );
};
