import React from "react";
import { Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";

export const NavigationLink = ({ children, label, permissions, url }) => {
  const [cookies] = useCookies(["user"]);

  if (
    permissions
      .map((permission) => (!!cookies?.user?.meta?.[permission] ? true : null))
      .filter((a) => a).length === 0
  )
    return null;

  return (
    <Nav.Link
      href={url}
      className={window.location.pathname === url && "active"}
    >
      {children} <span className="d-inline-block d-md-none">{label}</span>
    </Nav.Link>
  );
};
