import React from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import config from "../../../config.json";
import { MetaForm } from "../../Global/MetaForm";
import axios from "axios";
import { toast } from "react-toastify";

export const ClientModal = ({
  data,
  onHide,
  setClients,
  setData,
  setIsLoading,
  show,
}) => {
  const metaErrorMessage = () => {
    return (config.clientMetaTemplate
      .map((field) =>
        !data?.meta?.[field.name] ? `${field.label} is required` : null
      )
      .filter((a) => a) ?? [])[0];
  };

  const invalidMeta = () => {
    return (config.clientMetaTemplate
      .map((field) => (!data?.meta?.[field.name] ? true : null))
      .filter((a) => a) ?? [])[0];
  };

  const submit = () => {
    if (!data?.name) toast.error("Name is required");
    else if (invalidMeta()) toast.error(metaErrorMessage());
    else {
      setIsLoading(true);
      axios
        .post(
          `${config.api[window.location.hostname]}/clientAdd.php`,
          { ...data, meta: JSON.stringify(data?.meta ?? {}) },
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .catch((error) => toast.error(error))
        .then((response) => {
          if (response.data.clients) {
            setClients(response.data.clients);
            onHide();
            toast.success(
              `Client ${!data.id ? "added" : "updated"} successfully`
            );
          } else if (!!response?.data?.error) toast.error(response.data.error);

          setIsLoading(false);
        });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {!!data?.id ? "Edit" : "Add"} Client
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel className="mb-3" label="client name">
          <Form.Control
            value={data?.name ?? ""}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          ></Form.Control>
        </FloatingLabel>
        <MetaForm
          data={data}
          setData={setData}
          template={config.clientMetaTemplate}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          {!!data?.id ? "Edit" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
