import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { AccountModal } from "./AccountModal";
import axios from "axios";
import config from "../../config.json";
import { Loading } from "../Loading";

export const Accounts = () => {
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [clients, setClients] = useState(null);
  const [activeAccountData, setActiveAccountData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const addAccount = () => {
    setActiveAccountData({});
    setShowModal(true);
  };

  const editAccount = (data) => {
    setActiveAccountData({
      ...data,
      meta: !!data?.meta ? JSON.parse(data?.meta) : {},
    });
    setShowModal(true);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${config.api[window.location.hostname]}/accountList.php`)
      .then((response) => {
        setAccounts(response.data.accounts);
        setClients(response.data.clients);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loading />;
  if (!accounts) return null;

  return (
    <>
      <AccountModal
        data={activeAccountData}
        clients={clients}
        setAccounts={setAccounts}
        setData={setActiveAccountData}
        setIsLoading={setIsLoading}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <div className="mb-3 text-end">
        <Button onClick={addAccount}>
          <FontAwesomeIcon icon={faPlus} inverse /> Add New Account
        </Button>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>User Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(accounts ?? []).map((account) => (
            <tr key={account.id}>
              <td>
                {
                  clients
                    .map((client) =>
                      client.id === account.client_id ? client.name : null
                    )
                    .filter((a) => a)?.[0]
                }
              </td>
              <td>{account.username} </td>
              <td className="text-end">
                <Button variant="dark" onClick={() => editAccount(account)}>
                  <FontAwesomeIcon icon={faPencil} /> {}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
