import React from "react";
import { MetaFormText } from "./MetaFormText";
import { Col, Row } from "react-bootstrap";
import { MetaFormBoolean } from "./MetaFormBoolean";

export const MetaForm = ({ cols, data, setData, template }) => {
  return (
    <Row>
      {template.map((field) => (
        <Col key={field.name} md={cols ?? 6}>
          {field.type === "text" && (
            <MetaFormText data={data} field={field} setData={setData} />
          )}
          {field.type === "boolean" && (
            <MetaFormBoolean data={data} field={field} setData={setData} />
          )}
        </Col>
      ))}
    </Row>
  );
};
