import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCreditCard,
  faDollarSign,
  faDoorOpen,
  faHouse,
  faList,
  faSignature,
  faSitemap,
  faUser,
  faUsers,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { NavigationLink } from "./NavigationLink";

export const Navigation = () => {
  const [cookies, remove, setCookie] = useCookies(["user"]);

  const logout = () => {
    remove("user");
    setCookie("user", null);
  };

  if (!cookies?.user?.id) return null;
  return (
    <Navbar fixed="top" expand="md" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">
          <Image fluid src="/RG_Logo_Red-Black.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">
              <FontAwesomeIcon icon={faHouse} inverse />{" "}
              <span className="d-inline-block d-md-none">Home</span>
            </Nav.Link>

            <NavigationLink
              permissions={["isAdmin"]}
              url="/accounts"
              label="Accounts"
            >
              <FontAwesomeIcon icon={faUser} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={["isAdmin"]}
              url="/clients"
              label="Clients"
            >
              <FontAwesomeIcon icon={faSitemap} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={[
                "accountsCanCreate",
                "accountsCanEdit",
                "accountsCanView",
              ]}
              url="/clientAccounts"
              label="Accounts"
            >
              <FontAwesomeIcon icon={faUser} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={[
                "prospectsCanCreate",
                "prospectsCanEdit",
                "prospectsCanView",
              ]}
              url="/prospects"
              label="Prospects"
            >
              <FontAwesomeIcon icon={faList} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={["submissionsCanView"]}
              url="/submissions"
              label="Submissions"
            >
              <FontAwesomeIcon icon={faSignature} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={[
                "transactionsCanCreate",
                "transactionsCanEdit",
                "transactionsCanView",
              ]}
              url="/transactions"
              label="Transactions"
            >
              <FontAwesomeIcon icon={faCreditCard} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={["usersCanView"]}
              url="/users"
              label="Users"
            >
              <FontAwesomeIcon icon={faUsers} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={["reportHousefleCanView"]}
              url="/reportHousefile"
              label="House File Report"
            >
              <FontAwesomeIcon
                className="text-primary"
                icon={faWarehouse}
                inverse
              />
            </NavigationLink>

            <NavigationLink
              permissions={["reportProspectCanView"]}
              url="/reportProspect"
              label="Prospect Report"
            >
              <FontAwesomeIcon className="text-primary" icon={faList} inverse />
            </NavigationLink>

            <NavigationLink
              permissions={["reportITVCanView"]}
              url="/reportITV"
              label="ITV Report"
            >
              <>
                <FontAwesomeIcon className="text-primary" icon={faDollarSign} />
                <FontAwesomeIcon className="text-primary" icon={faClock} />
              </>
            </NavigationLink>

            <Nav.Link onClick={logout}>
              <FontAwesomeIcon icon={faDoorOpen} inverse />
              <span className="d-inline-block d-md-none">Logout</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
