import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import config from "../../../config.json";
import { MetaForm } from "../../Global/MetaForm";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export const ClientAccountModal = ({
  data,
  onHide,
  setAccounts,
  setData,
  setIsLoading,
  show,
}) => {
  const [cookies] = useCookies("user");

  const metaErrorMessage = () => {
    return (config.userMetaTemplate
      .map((field) =>
        !data?.meta?.[field.name] ? `${field.label} is required` : null
      )
      .filter((a) => a) ?? [])[0];
  };

  const invalidMeta = () => {
    return (config.userMetaTemplate
      .map((field) =>
        field.type !== "boolean" && !data?.meta?.[field.name] ? true : null
      )
      .filter((a) => a) ?? [])[0];
  };

  const submit = () => {
    if (!data?.username) toast.error("Name is required");
    else if (!data.id && !data?.userpassword)
      toast.error("Password is required");
    else if (invalidMeta()) toast.error(metaErrorMessage());
    else {
      setIsLoading(true);

      axios
        .post(
          `${config.api[window.location.hostname]}/clientAccountAdd.php`,
          {
            ...data,
            meta: JSON.stringify(data?.meta ?? {}),
            client_id: cookies.user.clientId,
          },
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .catch((error) => toast.error(error))
        .then((response) => {
          if (response.data.accounts) {
            setAccounts(response.data.accounts);
            onHide();
            toast.success(
              `Account ${!data.id ? "added" : "updated"} successfully`
            );
            setIsLoading(false);
          } else if (!!response?.data?.error) toast.error(response.data.error);
        });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {!!data?.id ? "Edit" : "Add"} User
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FloatingLabel className="mb-3" label="user name">
              <Form.Control
                value={data?.username ?? ""}
                onChange={(e) => setData({ ...data, username: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel className="mb-3" label="user password">
              <Form.Control
                value={data?.userpassword ?? ""}
                type="password"
                onChange={(e) =>
                  setData({ ...data, userpassword: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <MetaForm
          cols={3}
          data={data}
          setData={setData}
          template={config.userMetaTemplate}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          {!!data?.id ? "Edit" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
