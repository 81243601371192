import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export const MetaFormText = ({ data, field, setData }) => {
  return (
    <FloatingLabel className="mb-3" label={field.label}>
      <Form.Control
        value={data?.meta?.[field.name] ?? ""}
        onChange={(e) =>
          setData({
            ...data,
            meta: { ...data?.meta, [field.name]: e.target.value },
          })
        }
      ></Form.Control>
    </FloatingLabel>
  );
};
