import React from "react";
import {
  ButtonGroup,
  ButtonToolbar,
  FormGroup,
  FormLabel,
  ToggleButton,
} from "react-bootstrap";

export const MetaFormBoolean = ({ data, field, setData }) => {
  return (
    <FormGroup className="mb-3">
      <FormLabel>{field.label}</FormLabel>
      <ButtonToolbar>
        <ButtonGroup>
          <ToggleButton
            name={field.name}
            key={`${field.name}-false`}
            id={`${field.name}-false`}
            type="radio"
            value={false}
            variant={!data?.meta?.[field.name] ? "dark" : "outline-dark"}
            checked={!data?.meta?.[field.name]}
            onChange={() =>
              setData({
                ...data,
                meta: { ...data?.meta, [field.name]: false },
              })
            }
          >
            False
          </ToggleButton>
          <ToggleButton
            name={field.name}
            key={`${field.name}-true`}
            id={`${field.name}-true`}
            type="radio"
            value={true}
            variant={!!data?.meta?.[field.name] ? "dark" : "outline-dark"}
            checked={!!data?.meta?.[field.name]}
            onChange={() =>
              setData({
                ...data,
                meta: { ...data?.meta, [field.name]: true },
              })
            }
          >
            True
          </ToggleButton>
        </ButtonGroup>
      </ButtonToolbar>
    </FormGroup>
  );
};
