import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import config from "../../../config.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export const TransactionModal = ({
  data,
  onHide,
  setTransactions,
  setData,
  setIsLoading,
  show,
  /*--- Filters ---*/
  dateFrom,
  dateTo,
  pageIndex,
  pageSize,
  query,
  sortField,
  sortOrder,
}) => {
  const [cookies] = useCookies(["user"]);

  const submit = () => {
    if (!data?.message_id) toast.error("Message ID is required");
    else if (!data?.payment_date) toast.error("Date is required");
    else if (!data?.amount) toast.error("Amount is required");
    else if (
      !data?.billing_email ||
      !/^[^@]+@[^.]+\..+$/i.test(data.billing_email)
    )
      toast.error("Valid email is required");
    else setIsLoading(true);

    axios
      .post(
        `${config.api[window.location.hostname]}/transactionAdd.php`,
        {
          ...data,
          client_id: cookies.user.clientId,
          source: cookies.user.clientMeta.tableTransactions,
          /*--- Filters ---*/
          dateFrom,
          dateTo,
          pageIndex,
          pageSize,
          query,
          sortField,
          sortOrder,
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .catch((error) => toast.error(error))
      .then((response) => {
        if (response.data.transactions) {
          setTransactions(response.data.transactions);
          onHide();
          toast.success(
            `Transaction ${!data.id ? "added" : "updated"} successfully`
          );
          setIsLoading(false);
        } else if (!!response?.data?.error) toast.error(response.data.error);
      });
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {!!data?.id ? "Edit" : "Add"} Transaction
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="date*">
              <Form.Control
                type="date"
                value={data?.billing_date ?? ""}
                onChange={(e) =>
                  setData({ ...data, billing_date: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="source">
              <Form.Control
                value={data?.source ?? ""}
                onChange={(e) => setData({ ...data, source: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="message id*">
              <Form.Control
                value={data?.message_id ?? ""}
                onChange={(e) =>
                  setData({ ...data, message_id: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="donation id">
              <Form.Control
                value={data?.donation_id ?? ""}
                onChange={(e) =>
                  setData({ ...data, donation_id: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="amount*">
              <Form.Control
                type="number"
                value={data?.amount ?? ""}
                onChange={(e) => setData({ ...data, amount: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="recurring">
              <Form.Control
                value={data?.recurring ?? ""}
                onChange={(e) =>
                  setData({ ...data, recurring: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="donation recurring">
              <Form.Control
                value={data?.donation_recurring ?? ""}
                onChange={(e) =>
                  setData({ ...data, donation_recurring: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="first name">
              <Form.Control
                value={data?.first_name ?? ""}
                onChange={(e) =>
                  setData({ ...data, first_name: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="last name">
              <Form.Control
                value={data?.last_name ?? ""}
                onChange={(e) =>
                  setData({ ...data, last_name: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="billing email*">
              <Form.Control
                type="email"
                value={data?.billing_email ?? ""}
                onChange={(e) =>
                  setData({ ...data, billing_email: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="organization">
              <Form.Control
                value={data?.organization ?? ""}
                onChange={(e) =>
                  setData({ ...data, organization: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          {!!data?.id ? "Edit" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
