import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const SortableTH = ({
  children,
  field,
  onClick,
  sortField,
  sortOrder,
}) => {
  return (
    <th className="cursorPointer" onClick={() => onClick(field)}>
      {children}
      {sortField === field && sortOrder === "ASC" && (
        <FontAwesomeIcon className="float-end" icon={faCaretUp} />
      )}
      {sortField === field && sortOrder === "DESC" && (
        <FontAwesomeIcon className="float-end" icon={faCaretDown} />
      )}
    </th>
  );
};
