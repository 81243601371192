import React from "react";
import { Form, Pagination } from "react-bootstrap";

export const PaginationBar = ({
  pageIndex,
  pageSize,
  onUpdatePagination,
  tally,
}) => {
  const pageSpan = 2;

  const bindPageIndex = (value) =>
    Math.min(Math.ceil(tally / pageSize) - 1, Math.max(0, value));

  const onClick = (mode, value) => {
    let newPageIndex = pageIndex;

    if (mode === "set") newPageIndex = bindPageIndex(value);
    else if (mode === "inc") newPageIndex = bindPageIndex(newPageIndex + value);

    onUpdatePagination(newPageIndex, pageSize);
  };

  const onChange = (e) => {
    onUpdatePagination(pageIndex, e.target.value);
  };

  const pages = () => {
    let page = [];

    for (let p = pageIndex - pageSpan; p <= pageIndex + pageSpan; p++) {
      if (p >= 0)
        page.push(
          <Pagination.Item
            key={p}
            className="d-none d-md-block"
            onClick={() => onClick("set", p - 1)}
          >
            {p + 1}
          </Pagination.Item>
        );
    }

    if (pageIndex - pageSpan > 0)
      page = [
        <Pagination.Ellipsis
          key={`-${pageSpan}`}
          className="d-none d-md-block"
          onClick={() => onClick("set", pageIndex - pageSpan)}
        />,
        ...page,
      ];

    if (pageIndex + pageSpan < Math.ceil(tally / pageSize) - 1)
      page.push(
        <Pagination.Ellipsis
          key={`+${pageSpan}`}
          className="d-none d-md-block"
          onClick={() => onClick("set", pageIndex + pageSpan)}
        />
      );

    return page;
  };

  if (tally <= pageSize)
    return (
      <>
        <div className="d-block d-md-inline-block float-md-end">
          <Form.Select defaultValue={pageSize} onChange={onChange}>
            <option value={10}>10 items per page</option>
            <option value={25}>25 items per page</option>
            <option value={50}>50 items per page</option>
            <option value={100}>100 items per page</option>
          </Form.Select>
        </div>{" "}
        <div className="d-inline-block w-100 text-end">
          {parseInt(tally).toLocaleString("en-US")} total records
        </div>
      </>
    );

  if (tally > pageSize)
    return (
      <>
        <Pagination className="float-end">
          <Pagination.First onClick={() => onClick("set", 0)} />
          <Pagination.Prev onClick={() => onClick("inc", -1)} />
          {pages()}
          <Pagination.Next onClick={() => onClick("inc", 1)} />
          <Pagination.Last onClick={() => onClick("set", tally)} />
        </Pagination>

        <div className="d-block d-md-inline-block float-md-end me-0 me-md-3">
          <Form.Select defaultValue={pageSize} onChange={onChange}>
            <option value={10}>10 items per page</option>
            <option value={25}>25 items per page</option>
            <option value={50}>50 items per page</option>
            <option value={100}>100 items per page</option>
          </Form.Select>
        </div>

        <div className="d-inline-block w-100 text-end">
          {parseInt(tally).toLocaleString("en-US")} total records
        </div>
      </>
    );
};
