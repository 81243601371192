import React from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
} from "react-bootstrap";
import config from "../../../config.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useState } from "react";

export const ProspectUploadModal = ({
  onHide,
  setIsLoading,
  setProspects,
  setTally,
  show,
  /*--- Filters ---*/
  dateFrom,
  dateTo,
  pageIndex,
  pageSize,
  query,
  sortField,
  sortOrder,
}) => {
  const [cookies] = useCookies(["user"]);
  const [file, setFile] = useState(null);

  const submit = () => {
    if (!file) toast.error("File is required");
    else setIsLoading(true);

    axios
      .post(
        `${config.api[window.location.hostname]}/prospectUpload.php`,
        {
          file,
          client_id: cookies.user.clientId,
          source: cookies.user.clientMeta.tableProspects,
          /*--- Filters ---*/
          dateFrom,
          dateTo,
          pageIndex,
          pageSize,
          query,
          sortField,
          sortOrder,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .catch((error) => toast.error(error))
      .then((response) => {
        if (response?.data?.prospects) {
          setProspects(response.data.prospects);
          setTally(response.data.tally);
          onHide();
          toast.success(`Import successfully`);
          setIsLoading(false);
        } else {
          toast.error(
            "The upload seems to have failed. Please view the console for more information."
          );
          console.error(response.data);
          if (!!response?.data?.errors) toast.error(response.data.errors);
        }
      });
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>Upload Prospect CSV</Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <FloatingLabel className="mb-3" label="CSV*">
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              ></Form.Control>
            </FloatingLabel>

            <ListGroup>
              <ListGroupItem variant="info">
                CSV should contain the following fields in the header row:{" "}
                <i>date</i>,<i>vendor</i>,<i>placement</i>,<i>cost</i>,
                <i>source</i>,<i>message_id</i>,<i>creative</i>,
                <i>organization</i>
              </ListGroupItem>
              <ListGroupItem variant="info">
                <i>date</i> should utilize the format "%c/%e/%Y" (i.e.
                "1/2/2023")
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
