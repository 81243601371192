import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import config from "../../../config.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export const ProspectModal = ({
  data,
  onHide,
  setProspects,
  setTally,
  setData,
  setIsLoading,
  show,
  /*--- Filters ---*/
  dateFrom,
  dateTo,
  pageIndex,
  pageSize,
  query,
  sortField,
  sortOrder,
}) => {
  const [cookies] = useCookies(["user"]);

  const submit = () => {
    if (!data?.date) toast.error("Date is required");
    else if (!data?.vendor) toast.error("Vendor is required");
    else if (!data?.placement) toast.error("Placement is required");
    else if (!data?.cost) toast.error("Cost is required");
    else if (!data?.source) toast.error("Source is required");
    else if (!data?.creative) toast.error("Creative is required");
    else if (!data?.organization) toast.error("Organization is required");
    else if (!data?.message_id) toast.error("Message Id is required");
    else setIsLoading(true);
    axios
      .post(
        `${config.api[window.location.hostname]}/prospectAdd.php`,
        {
          ...data,
          client_id: cookies.user.clientId,
          source: cookies.user.clientMeta.tableProspects,
          /*--- Filters ---*/
          dateFrom,
          dateTo,
          pageIndex,
          pageSize,
          query,
          sortField,
          sortOrder,
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .catch((error) => toast.error(error))
      .then((response) => {
        if (response.data.prospects) {
          setProspects(response.data.prospects);
          setTally(response.data.tally);
          onHide();
          toast.success(
            `Prospect ${!data.id ? "added" : "updated"} successfully`
          );
          setIsLoading(false);
        } else if (!!response?.data?.error) toast.error(response.data.error);
      });
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {!!data?.id ? "Edit" : "Add"} Prospect
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="date*">
              <Form.Control
                type="date"
                value={data?.date ?? ""}
                onChange={(e) => setData({ ...data, date: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="vendor*">
              <Form.Control
                value={data?.vendor ?? ""}
                onChange={(e) => setData({ ...data, vendor: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="placement*">
              <Form.Control
                value={data?.placement ?? ""}
                onChange={(e) =>
                  setData({ ...data, placement: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="cost*">
              <Form.Control
                type="number"
                value={data?.cost ?? ""}
                onChange={(e) => setData({ ...data, cost: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="source*">
              <Form.Control
                value={data?.source ?? ""}
                onChange={(e) => setData({ ...data, source: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="creative*">
              <Form.Control
                value={data?.creative ?? ""}
                onChange={(e) => setData({ ...data, creative: e.target.value })}
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="organization*">
              <Form.Control
                value={data?.organization ?? ""}
                onChange={(e) =>
                  setData({ ...data, organization: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col xs={4}>
            <FloatingLabel className="mb-3" label="message id*">
              <Form.Control
                value={data?.message_id ?? ""}
                onChange={(e) =>
                  setData({ ...data, message_id: e.target.value })
                }
              ></Form.Control>
            </FloatingLabel>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          {!!data?.id ? "Edit" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
