import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import { SortableTH } from "../Global/SortableTH";
import { FilterBar } from "../Global/FilterBar";
import { PaginationBar } from "../Global/PaginationBar";
import { useCookies } from "react-cookie";
import { Loading } from "../Loading";

export const Submissions = () => {
  const [cookies] = useCookies(["user"]);
  const [tally, setTally] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [sortField, setSortField] = useState("submit_dt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [query, setQuery] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const updateQuery = (newQuery, newDateFrom, newDateTo) => {
    setQuery(newQuery);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  const updatePagination = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const updateSortField = (field) => {
    if (field === sortField) {
      if (sortOrder === "ASC") setSortOrder("DESC");
      else setSortOrder("ASC");
    }

    setSortField(field);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `${
          config.api[window.location.hostname]
        }/submissionList.php?cache=${new Date().getTime()}&source=${
          cookies.user.clientMeta.tableSubmission
        }&query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortField=${sortField}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .then((response) => {
        setTally(response.data.tally);
        setSubmissions(response.data.submissions);
        setIsLoading(false);
      });
  }, [
    cookies,
    dateFrom,
    dateTo,
    pageIndex,
    pageSize,
    query,
    sortField,
    sortOrder,
  ]);

  if (isLoading) return <Loading />;
  if (!submissions) return null;

  return (
    <>
      <div>
        <Row className="alignItemsBaseline">
          <Col md={9} className="mb-3">
            <FilterBar
              dateFrom={dateFrom}
              dateTo={dateTo}
              query={query}
              onUpdateQuery={updateQuery}
            />
          </Col>
        </Row>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <SortableTH
              field="email"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Email
            </SortableTH>
            <SortableTH
              field="first_name"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              First
            </SortableTH>
            <SortableTH
              field="last_name"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Last
            </SortableTH>
            <SortableTH
              field="phone"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Phone
            </SortableTH>
            <SortableTH
              field="zip_code"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Zip
            </SortableTH>
            <SortableTH
              field="message_id"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Message ID
            </SortableTH>
            <SortableTH
              field="source"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Source
            </SortableTH>
            <SortableTH
              field="organization"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Org.
            </SortableTH>
            <SortableTH
              field="submit_dt"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Submit Date
            </SortableTH>
            <SortableTH
              field="lastactiondate"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Last Action Date
            </SortableTH>
          </tr>
        </thead>
        <tbody>
          {(submissions ?? []).map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.email} </td>
              <td>{transaction.first_name} </td>
              <td>{transaction.last_name} </td>
              <td>{transaction.phone} </td>
              <td>{transaction.zip_code} </td>
              <td>{transaction.message_id} </td>
              <td>{transaction.source} </td>
              <td>{transaction.organization} </td>
              <td>{transaction.submit_dt} </td>
              <td>{transaction.lastactiondate} </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <PaginationBar
        pageIndex={pageIndex}
        pageSize={pageSize}
        tally={tally}
        onUpdatePagination={updatePagination}
      />
    </>
  );
};
