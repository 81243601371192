import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./Components/Home";
import { Login } from "./Components/Login";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigation } from "./Components/Navigation";
import { Clients } from "./Components/Clients";
import { Accounts } from "./Components/Accounts";
import { Transactions } from "./Components/Transactions";
import { Prospects } from "./Components/Prospects";
import { Submissions } from "./Components/Submissions";
import { Users } from "./Components/Users";
import { ClientAccounts } from "./Components/ClientAccounts";
import { HousefileReport } from "./Components/HousefileReport";
import { ProspectReport } from "./Components/ProspectReport";
import { ITVReport } from "./Components/ITVReport";

function App() {
  const wrapper = (permissions, element) => (
    <>
      <Navigation></Navigation>
      <Container fluid className="mainContainer">
        <ToastContainer></ToastContainer>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <Login permissions={permissions}>{element}</Login>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
    </>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={wrapper(null, <Home />)}></Route>
        <Route
          path="/accounts"
          element={wrapper(["isAdmin"], <Accounts />)}
        ></Route>
        <Route
          path="/clients"
          element={wrapper(["isAdmin"], <Clients />)}
        ></Route>
        <Route
          path="/clientAccounts"
          element={wrapper(
            ["accountsCanCreate", "accountsCanEdit", "accountsCanView"],
            <ClientAccounts />
          )}
        ></Route>
        <Route
          path="/prospects"
          element={wrapper(
            ["prospectsCanCreate", "prospectsCanEdit", "prospectsCanView"],
            <Prospects />
          )}
        ></Route>
        <Route
          path="/reportHousefile"
          element={wrapper(["reportHousefleCanView"], <HousefileReport />)}
        ></Route>
        <Route
          path="/reportITV"
          element={wrapper(["reportITVCanView"], <ITVReport />)}
        ></Route>
        <Route
          path="/reportProspect"
          element={wrapper(["reportProspectCanView"], <ProspectReport />)}
        ></Route>
        <Route
          path="/submissions"
          element={wrapper(["submissionsCanView"], <Submissions />)}
        ></Route>
        <Route
          path="/transactions"
          element={wrapper(
            [
              "transactionsCanCreate",
              "transactionsCanEdit",
              "transactionsCanView",
            ],
            <Transactions />
          )}
        ></Route>
        <Route
          path="/users"
          element={wrapper(["usersCanView"], <Users />)}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
