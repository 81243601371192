import React from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
} from "react-bootstrap";
import config from "../../../config.json";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useState } from "react";

export const TransactionUploadModal = ({
  onHide,
  setIsLoading,
  setTransactions,
  show,
  /*--- Filters ---*/
  dateFrom,
  dateTo,
  pageIndex,
  pageSize,
  query,
  sortField,
  sortOrder,
}) => {
  const [cookies] = useCookies(["user"]);
  const [files, setFiles] = useState(null);

  const submit = () => {
    if (!files) toast.error("At least one file is required");
    else setIsLoading(true);

    axios
      .post(
        `${config.api[window.location.hostname]}/transactionUpload.php`,
        {
          files,
          client_id: cookies.user.clientId,
          source: cookies.user.clientMeta.tableTransactions,
          /*--- Filters ---*/
          dateFrom,
          dateTo,
          pageIndex,
          pageSize,
          query,
          sortField,
          sortOrder,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .catch((error) => toast.error(error))
      .then((response) => {
        if (response.data.transactions) {
          setTransactions(response.data.transactions);
          onHide();
          toast.success(`Import successfully`);
          setIsLoading(false);
        } else {
          toast.error(
            "The upload seems to have failed. Please view the console for more information."
          );
          console.error(response);
          if (!!response?.data?.errors) toast.error(response.data.errors);
        }
      });
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>Upload Transaction CSV</Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <FloatingLabel className="mb-3" label="CSV*">
              <Form.Control
                type="file"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              ></Form.Control>
            </FloatingLabel>

            <ListGroup>
              <ListGroupItem variant="info">
                CSV should contain the following fields in the header row:{" "}
                <i>Source</i>,<i>Message ID</i>,<i>Donation ID</i>,
                <i>Total Charged ($ Amount)</i>,<i>Payment Date</i>,
                <i>Recurring</i>,<i>Donation Recurring</i>,<i>First Name</i>,
                <i>Last Name</i>,<i>Billing Email Address</i>,
                <i>Organization</i>
              </ListGroupItem>
              <ListGroupItem variant="info">
                <i>Payment Date</i> should utilize the format "%c/%e/%y %H:%i"
                (i.e. "1/2/20 13:05")
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
