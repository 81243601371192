import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import { SortableTH } from "../Global/SortableTH";
import { FilterBar } from "../Global/FilterBar";
import { PaginationBar } from "../Global/PaginationBar";
import { useCookies } from "react-cookie";
import { Loading } from "../Loading";

export const HousefileReport = () => {
  const [cookies] = useCookies(["user"]);
  const [tally, setTally] = useState(null);
  const [housefile, setHousefile] = useState(null);
  const [sortField, setSortField] = useState("message_id");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [sSourceList, setSScourceList] = useState([]);
  const [sSource, setSScource] = useState("hf");
  const [query, setQuery] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  let totals = {};

  const updateQuery = (newQuery, newDateFrom, newDateTo) => {
    setQuery(newQuery);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  const updatePagination = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const updateSortField = (field) => {
    if (field === sortField) {
      if (sortOrder === "ASC") setSortOrder("DESC");
      else setSortOrder("ASC");
    }

    setSortField(field);
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(
        `${
          config.api[window.location.hostname]
        }/housefileReport.php?cache=${new Date().getTime()}&source=${
          cookies.user.clientMeta.procedureHousefile
        }&sSource=${sSource}&query=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortField=${sortField}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .then((response) => {
        setTally(response.data.tally);
        setSScourceList(response.data.source);
        setHousefile(response.data.housefile);
        setIsLoading(false);
      });
  }, [
    cookies,
    dateFrom,
    dateTo,
    pageIndex,
    pageSize,
    sSource,
    query,
    sortField,
    sortOrder,
  ]);

  if (isLoading) return <Loading />;
  if (!housefile) return null;

  return (
    <>
      <div>
        <Row className="alignItemsBaseline">
          <Col md={9} className="mb-3">
            <FilterBar
              dateFrom={dateFrom}
              dateTo={dateTo}
              query={query}
              onUpdateQuery={updateQuery}
              prefix={
                <FloatingLabel label="source">
                  <Form.Select
                    defaultValue={sSource ?? ""}
                    onChange={(e) => setSScource(e.target.value)}
                  >
                    {sSourceList.map((source) => (
                      <option value={source}>{source}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              }
            />
          </Col>
          <Col md={3} className="mb-3 text-end"></Col>
        </Row>
      </div>

      <Table bordered striped responsive>
        <thead>
          <tr>
            <SortableTH
              field="message_id"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Message Id
            </SortableTH>
            <SortableTH
              field="signatures"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Signatures
            </SortableTH>
            <SortableTH
              field="acquired"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Acquired
            </SortableTH>
            <SortableTH
              field="oneTimeDonationCount"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              One Time Count
            </SortableTH>
            <SortableTH
              field="oneTimeDonationAmount"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              One Time Amount
            </SortableTH>
            <SortableTH
              field="recurringDonationCount"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Recurring Count
            </SortableTH>
            <SortableTH
              field="RecurringDonationAmount"
              onClick={updateSortField}
              sortField={sortField}
              sortOrder={sortOrder}
            >
              Recurring Amount
            </SortableTH>
          </tr>
        </thead>
        <tbody>
          {(housefile ?? []).map((record) => {
            totals.signatures = (totals.signatures ?? 0) + parseInt(record.signatures ?? 0, 10);
            totals.acquired = (totals.acquired ?? 0) + parseInt(record.acquired ?? 0, 10);
            totals.oneTimeDonationCount = (totals.oneTimeDonationCount ?? 0) + parseInt(record.oneTimeDonationCount ?? 0, 10);
            totals.oneTimeDonationAmount = (totals.oneTimeDonationAmount ?? 0) + parseFloat(record.oneTimeDonationAmount ?? 0);
            totals.recurringDonationCount = (totals.recurringDonationCount ?? 0) + parseInt(record.recurringDonationCount ?? 0, 10);
            totals.recurringDonationAmount = (totals.recurringDonationAmount ?? 0) + parseFloat(record.recurringDonationAmount ?? 0);
            
            return <tr key={record.message_id}>
              <td>{record.message_id} </td>
              <td>
                {parseInt(record.signatures ?? "0").toLocaleString("en-US")}
              </td>
              <td>
                {parseInt(record.acquired ?? "0").toLocaleString("en-US")}
              </td>
              <td>
                {parseInt(record.oneTimeDonationCount ?? "0").toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                $
                {parseFloat(record.oneTimeDonationAmount ?? "0").toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                {parseInt(record.recurringDonationCount ?? "0").toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                $
                {parseFloat(
                  record.recurringDonationAmount ?? "0"
                ).toLocaleString("en-US")}
              </td>
            </tr>
          })}
          <tr>
            <td/>
            <td>{totals.signatures.toLocaleString("en-US")}</td>  
            <td>{totals.acquired.toLocaleString("en-US")}</td>  
            <td>{totals.oneTimeDonationCount.toLocaleString("en-US")}</td>  
            <td>${totals.oneTimeDonationAmount.toLocaleString("en-US")}</td>  
            <td>{totals.recurringDonationCount.toLocaleString("en-US")}</td>  
            <td>${totals.recurringDonationAmount.toLocaleString("en-US")}</td>  
          </tr>

        </tbody>
      </Table>

      <PaginationBar
        pageIndex={pageIndex}
        pageSize={pageSize}
        tally={tally}
        onUpdatePagination={updatePagination}
      />
    </>
  );
};
