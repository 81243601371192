import React from "react";
import { Container, Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
    <Container fluid className="mt-5 text-center">
      <Spinner animation="grow"></Spinner>
    </Container>
  );
};
