import React, { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import config from "../../config.json";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { Loading } from "../Loading";

//9RtK5MDM5foCsF

export const Login = ({ children, permissions }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    if (!name) toast.error("Name is required");
    else if (!password) toast.error("Password is required");
    else setIsLoading(true);

    axios
      .post(
        `${config.api[window.location.hostname]}/login.php`,
        { name, password },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .catch((error) => toast.error(error))
      .then((response) => {
        if (!!response?.data?.user?.id) {
          setIsLoading(false);
          setCookie("user", {
            ...response.data.user,
            meta: JSON.parse(response?.data?.user?.meta ?? "{}"),
            clientMeta: JSON.parse(response?.data?.user?.clientMeta ?? "{}"),
          });
          navigate(0);
        } else if (!!response?.data?.error) toast.error(response.data.error);
      });
  };

  if (isLoading) return <Loading />;

  if (
    !!permissions &&
    permissions
      .map((permission) => (!!cookies?.user?.meta?.[permission] ? true : null))
      .filter((a) => a).length === 0
  )
    return <Navigate to="/" />;

  if (!cookies.user)
    return (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel className="mb-3" label="name">
            <Form.Control
              value={name ?? ""}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
          <FloatingLabel label="password">
            <Form.Control
              type="password"
              value={password ?? ""}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={login}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    );
  else return children;
};
